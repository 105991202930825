import { Button, Checkbox, CheckboxProps, Divider, Modal, Spin, message } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { ServerController } from '../../../api/server.controller';
import type { GetSuperAdminExerciseResDto } from '../../../api/super-admin/res/get-super-admin-exercise-res.dto';

type Props = { userId: string };

export const AddExercisesComponent = (props: Props) => {
  const [allExercisesState, setAllExercisesState] = useState<GetSuperAdminExerciseResDto[] | undefined>(undefined);

  const [isLoading, setLoading] = useState(true);
  const load = useCallback(async () => {
    setLoading(true);
    const allExercisesResponse = await ServerController.Exercise.getAll();
    if (!IsErrorResDto(allExercisesResponse)) {
      setAllExercisesState(allExercisesResponse);
    }
    setLoading(false);
  }, []);
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const checkAll = (allExercisesState?.length ?? -1) > 0   && allExercisesState?.length === checkedList.length;
  const indeterminate = !allExercisesState || allExercisesState?.length === 0
    ? false
    : checkedList.length > 0 && checkedList.length < allExercisesState.length;

  const onChange = (checkedValue: CheckboxValueType[]) => {
    setCheckedList(checkedValue);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    if (!allExercisesState) {
      return;
    }
    setCheckedList(e.target.checked ? allExercisesState.map((x) => x.id): []);
  };
  const openModalAndGetUserExercises = async () => {
    setIsModalOpen(true);

    await load();
    const userExercises = await ServerController.ExerciseUser.get(props.userId);
    if (!IsErrorResDto(userExercises)) {
      setCheckedList(userExercises.map((x) => x.exerciseId));
    }
  };

  const saveUserExercises = async () => {
    const response = await ServerController.ExerciseUser.post(
      {
        exerciseIds: checkedList.map((x) => x as string),
      },
      props.userId,
    );
    if(!IsErrorResDto(response)){
      message.success(t`Modules assigned`);
      setIsModalOpen(false);
    }
    else {
      message.error(t`Something went wrong, please try again`);
    }
  };

  const clearAllStatesAndCloseModal = () => {
    setIsModalOpen(false);
    setCheckedList([]);
    setAllExercisesState([]);
  };
  return (
    <>
      <Button type="primary" onClick={() => openModalAndGetUserExercises()}>{t`Assign modules`}</Button>
      <Modal
        open={isModalOpen}
        destroyOnClose
        onCancel={() => clearAllStatesAndCloseModal()}
        onOk={() => {
          saveUserExercises();
        }}
      >
        <Spin spinning={isLoading}></Spin>

        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          {t`All`}
        </Checkbox>
        <Divider />
        {allExercisesState && (
          <Checkbox.Group
            options={allExercisesState.map((x) => ({ value: x.id, label: x.name }))}
            value={checkedList}
            onChange={onChange}
          />
        )}
      </Modal>
    </>
  );
};

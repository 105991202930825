import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { AuthContext } from '../context/auth-context.provider';
import { RoutesDefinitions } from './route-definitions';

export const PagesRouter = () => {
  const { checkAuthToken } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    checkAuthToken();
  }, [location, checkAuthToken]);

  return (
    <Routes>
      {RoutesDefinitions.map((x) => {
        return (
          <Route key={x.path} path={x.path} element={x.element}>
            {x.subroutes.map((subroute) => {
              const isIndexRoute = subroute.path === '';
              if (isIndexRoute) {
                return <Route key={subroute.path} index element={subroute.element} />;
              }
              return <Route key={subroute.path} path={subroute.path} element={subroute.element} />;
            })}
          </Route>
        );
      })}
      <Route path="*" element={<Navigate to="/admins" />} />
    </Routes>
  );
};

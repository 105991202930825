import { Button, Checkbox, Col, InputNumber, Popconfirm, Row, Table, Tag, message } from 'antd';

import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AdminUserResDto } from '../../api/admin-user/res/admin-user-res.dto';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { AddExercisesComponent } from './components/add-exercises.component';
import { AddressComponent } from './components/address.component';
import ImpersonateUserComponent from './components/impersonate-user.component';
import { AccountTypeEnum } from './enums';

const translationPrefix = `usersPage`;
let timer: any;

export const CoachesPage = function CoachesPage() {
  const { t } = useTranslation();
  const [users, setUsers] = useState<AdminUserResDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const [sortByString, setSortByString] = useState('-users.createdAt');
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 10,
  });
  const load = useCallback(async () => {
    const response = await ServerController.SuperAdminCoach.get({
      page: pageState.page,
      limit: pageState.limit,
      filters:
        !searchState || searchState === ''
          ? undefined
          : {
              'filters[firstName]': searchState,
              'filters[lastName]': searchState,
              'filters[normalizedEmail]': searchState,
            },

      order: {
        order: sortByString,
      },
    });
    if (!IsErrorResDto(response)) {
      setUsers(response.items);
      setTotal(response.total ?? 0);
    }
    setLoading(false);
  }, [pageState, sortByString]);
  useEffect(() => {
    load();
  }, [load]);

  const [activateLoading, setActivateLoading] = useState(false);

  const onActivateClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    const confirmed = window.confirm('This will activate the user, are you sure?');
    if (!confirmed) {
      return;
    }
    setActivateLoading(true);
    const response = await ServerController.SuperAdminUserActivateTrial.post({ id: e.currentTarget.value });
    if (!IsErrorResDto(response)) {
      await load();
    }
    setActivateLoading(false);
  };

  const onDeActivateClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    const confirmed = window.confirm('This will deactivate the user, are you sure?');
    if (!confirmed) {
      return;
    }
    setActivateLoading(true);
    const response = await ServerController.SuperAdminUserDeActivateTrial.post({ id: e.currentTarget.value });
    if (!IsErrorResDto(response)) {
      await load();
    }
    setActivateLoading(false);
  };

  const [userNumberLoading, setUserNumberLoading] = useState<{ [key: string]: boolean }>();
  const onNumberOfUsersChange = async (numberOfUsers: number | null, userId: string) => {
    const confirmed = window.confirm('This will increase number of users this user can add, are you sure?');
    if (!confirmed) {
      return;
    }

    numberOfUsers ??= 0;
    setUserNumberLoading((oldState) => {
      return { ...oldState, [userId]: true };
    });

    await ServerController.SuperAdminUserNumberOfUsers.post({ userId, numberOfUsers });
    setUserNumberLoading((oldState) => {
      return { ...oldState, [userId]: false };
    });
  };

  const columns: ColumnsType<AdminUserResDto> = [
    {
      title: t(`${translationPrefix}.name`),
      key: 'users.firstName',
      sorter: true,
      width: 240,
      render: (user: AdminUserResDto) => `${user.firstName ?? ''} ${user.lastName ?? ''}`,
    },
    {
      title: t(`${translationPrefix}.email`),
      key: 'users.normalizedEmail',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: t(`${translationPrefix}.phoneNumber`),
      dataIndex: 'phoneNumber',
      key: 'users.phoneNumber',
      sorter: true,
    },
    {
      title: t(`${translationPrefix}.licenseDetails`),
      key: 'planDetails',
      render: (user: AdminUserResDto) => (
        <>
          {user.subscription.plan ? (
            <div>
              <b>Licence:</b> {user.subscription.planDisplayName}
            </div>
          ) : (
            ''
          )}
          <div className="my-2">
            {user.subscription.plan ? (
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <b>User count:</b>
                </div>
                <div>
                  <span> </span>
                  <InputNumber
                    min={0}
                    className="mw-50"
                    addonBefore={<span>{user.totalAddedUsers.toString()} /</span>}
                    disabled={userNumberLoading?.[user.id] ?? false}
                    defaultValue={user.numberOfUsers}
                    bordered={true}
                    onChange={(x) => {
                      onNumberOfUsersChange(x, user.id);
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <span>
            <b>Type:</b>{' '}
            {user.userRoles.includes(AccountTypeEnum.Athlete) && user.userRoles.includes(AccountTypeEnum.Coach) ? (
              <Tag color="yellow">
                {' '}
                {user.userRoles
                  .map((x) => {
                    const roleTranslation = `roles.${x}`;
                    const translated = t(roleTranslation);
                    return translated;
                  })
                  .join(' AND ')}
              </Tag>
            ) : user.userRoles.includes(AccountTypeEnum.Athlete) ? (
              <Tag color="geekblue">
                {user.userRoles
                  .map((x) => {
                    const roleTranslation = `roles.${x}`;
                    const translated = t(roleTranslation);
                    return translated;
                  })
                  .join(' ')}
              </Tag>
            ) : (
              <Tag color="green">
                {' '}
                {user.userRoles
                  .map((x) => {
                    const roleTranslation = `roles.${x}`;
                    const translated = t(roleTranslation);
                    return translated;
                  })
                  .join(' ')}
              </Tag>
            )}
          </span>
        </>
      ),
    },
    {
      title: t(`${translationPrefix}.activated`),
      sorter: true,
      key: 'users.trialStart',
      render: (user: AdminUserResDto) => (
        <>
          {!user.userRoles.includes(AccountTypeEnum.Coach) ? (
            <></>
          ) : user.trialStart ? (
            <span>
              Active From: {new Intl.DateTimeFormat('en-us', {}).format(dayjs(user.trialStart).toDate())}
              <Button
                className="ms-2"
                onClick={onDeActivateClick}
                value={user.id}
                type="dashed"
                loading={activateLoading}
                danger
              >
                Deactivate
              </Button>
            </span>
          ) : (
            <span>
              <Button onClick={onActivateClick} value={user.id} type="primary" loading={activateLoading}>
                Activate
              </Button>
            </span>
          )}
        </>
      ),
    },
    {
      title: t(`${translationPrefix}.dateOfPurchase`),
      key: 'subscriptions.createdAt',
      sorter: true,
      render: (user: AdminUserResDto) => (
        <>
          {user.subscription.createdAt &&
            new Intl.DateTimeFormat('en-us', {}).format(dayjs(user.subscription.createdAt).toDate())}
        </>
      ),
    },
    {
      title: t(`${translationPrefix}.addresses`),
      render: (user: AdminUserResDto) => (
        <>
          <AddressComponent address={user.shippingAddress} label="Shipping address" />
          <AddressComponent address={user.billingAddress} label="Billing address" />
        </>
      ),
    },
    // {
    //   title: t(`${translationPrefix}.externalApis`),
    //   render: (user: AdminUserResDto) => (
    //     <>
    //       {user.stripeExternalId ? (
    //         <>
    //           {' '}
    //           <Button
    //             href={`${user.salesForceCustomerUrl}`}
    //             type="link"
    //             disabled={!user.salesForceExternalId}
    //             target="_blank"
    //             title={!user.salesForceExternalId ? `Does not exist in Sales Force` : ''}
    //           >
    //             <img src="/images/salesforce.ico" width="32" height="32" alt="SalesForce Contact" />
    //           </Button>
    //           <Button
    //             href={`${user.stripeCustomerUrl}`}
    //             type="link"
    //             disabled={!user.stripeExternalId}
    //             target="_blank"
    //             title={!user.stripeExternalId ? `Does not exist in Stripe` : ''}
    //           >
    //             <img src="/images/stripe.ico" width="32" height="32" alt="Stripe Customer" />
    //           </Button>
    //         </>
    //       ) : (
    //         ''
    //       )}
    //     </>
    //   ),
    // },
    {
      title: t`Data Recording enabled`,
      key: 'dataRecordingCheckbox',
      dataIndex: 'hasDataRecordingEnabled',
      render: (_, record: AdminUserResDto) => {
        return (
          <Row justify="center" align="middle">
            <Col>
              <Checkbox
                checked={record.hasDataRecording}
                onChange={async (e) => {
                  await ServerController.DataRecording.patch({ hasDataRecording: e.target.checked }, record.id);
                  await load();
                }}
              ></Checkbox>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Additional actions',
      key: 'additionalActions',
      render: (user: AdminUserResDto) => (
        <>
          <div className="d-flex my-2 justify-space-between">
            <ImpersonateUserComponent userId={user.id} />
            <Button
              onClick={async () => {
                const response = await ServerController.CoachSubscription.patch(user.id);
                if (!IsErrorResDto(response)) {
                  message.info(t`License extended`);
                }
                await load();
              }}
            >{t`Extend license`}</Button>
          </div>

          <div className="d-flex justify-space-between" >
            <Popconfirm
              title={t`Sure to delete?`}
              onConfirm={async (_) => {
                await ServerController.SuperAdminCoach.delete(user.id);
                await load();
              }}
            >
              <Button danger onClick={async () => {}}>{t`Delete user`}</Button>
            </Popconfirm>
            <AddExercisesComponent userId={user.id} />
          </div>
        </>
      ),
    },
  ];

  const onSearchChange = async (e?: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const value = e?.target?.value;

    setSearchState(value || '');

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      const response = await ServerController.SuperAdminCoach.get({
        page: 0,
        limit: 10,
        order: {
          order: sortByString,
        },
        filters:
          !value || value === ''
            ? undefined
            : {
                'filters[firstName]': value,
                'filters[lastName]': value,
                'filters[normalizedEmail]': value,
              },
      });
      if (!IsErrorResDto(response)) {
        setUsers(response.items);
      }
      setLoading(false);
    }, 250);
  };

  const navigate = useNavigate();

  const [searchState, setSearchState] = useState('');
  const onSearch = async (value: string, e: any) => {
    e.target.value = value;
    return onSearchChange(e);
  };
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<AdminUserResDto>,
  ) => {
    const sortByUserCreatedDateDefault = '-users.createdAt';
    const sorterOrderMapping = {
      ascend: '+',
      descend: '-',
    };
    let orderByString = sortByUserCreatedDateDefault;
    if (sorter && Object.keys(sorter).length !== 0) {
      orderByString = sorterOrderMapping[sorter.order ?? 'ascend'] + sorter.columnKey;
    }
    setSortByString(orderByString);
  };

  return (
    <>
      <div className="d-flex justify-space-between align-items-center mb-2">
        <div>
          <Search
            placeholder="Search"
            onChange={onSearchChange}
            allowClear
            size="middle"
            style={{ color: 'blue', backgroundColor: 'var(--ant-primary-color) !important;' }}
            onSearch={onSearch}
            value={searchState}
          />
        </div>
        <div>
          <Button type="primary" onClick={(x) => navigate('add-coach')}>
            Add new Admin
          </Button>
        </div>
      </div>
      <Table
        bordered
        size="small"
        pagination={{
          position: ['bottomRight'],
          pageSize: pageState.limit,
          total,
          onChange: (page, pageSize) => setPageState({ page, limit: pageSize }),
        }}
        columns={columns}
        dataSource={users}
        loading={loading}
        onChange={onTableChange as any}
        scroll={{ x: true }}
      ></Table>
    </>
  );
};

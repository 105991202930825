import { AppstoreAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const SideBarLinks = () => {
  const { t } = useTranslation();

  return [
    {
      key: 'coaches',
      label: t('sideBar.coaches'),
      icon: <UserSwitchOutlined />,
    },
   
    {
      key: 'exercise',
      label: t('sideBar.exercise'),
      icon: <AppstoreAddOutlined />,
    },
  ];
};

import { AddCoachPage } from './add-coach.page';
import { CoachesPage } from './coaches.page';
import { CoachesPageOutlet } from './coaches.page-outlet';

export const CoachRoutes = {
  path: '/admins',
  element: <CoachesPageOutlet />,
  subroutes: [
    {
      path: '',
      element: <CoachesPage />,
    },

    {
      path: 'add-coach',
      element: <AddCoachPage />,
    },
  ],
};

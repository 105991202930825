import { PublicPlanServer } from './public/public-plan.server';
import { PublicTokenLoginServer } from './public/public-token-login.server';
import { AuthServer } from './server-subclasses/auth';
import { SuperAdminAndroidServer } from './super-admin/super-admin-android.server';
import { SuperAdminCoachDataRecordingUserServer } from './super-admin/super-admin-coach-data-recording.server';
import { CoachSubscriptionServer } from './super-admin/super-admin-coach-subscription.server';
import { SuperAdminCoachServer } from './super-admin/super-admin-coach.server';
import { SuperAdminExerciseUserServer } from './super-admin/super-admin-exercise-user.server';
import { SuperAdminExerciseServer } from './super-admin/super-admin-exercise.server';
import { SuperAdminUserActivateTrialServer } from './super-admin/super-admin-user-activate-trial.server';
import { SuperAdminUserDeActivateTrialServer } from './super-admin/super-admin-user-deactivate-trial.server';
import { SuperAdminUserImpersonateServer } from './super-admin/super-admin-user-impersonate.server';
import { SuperAdminUserNumberOfUsersServer } from './super-admin/super-admin-user-number-of-users.server';

export class ServerController {
  static Auth: AuthServer = new AuthServer();
  static PublicTokenLogin: PublicTokenLoginServer = new PublicTokenLoginServer();
  static SuperAdminCoach = new SuperAdminCoachServer();
  static SuperAdminUserActivateTrial = new SuperAdminUserActivateTrialServer();
  static SuperAdminUserDeActivateTrial = new SuperAdminUserDeActivateTrialServer();
  static SuperAdminUserNumberOfUsers = new SuperAdminUserNumberOfUsersServer();
  static PublicPlan = new PublicPlanServer();
  static UserImpersonate = new SuperAdminUserImpersonateServer();
  static Android = new SuperAdminAndroidServer();
  static Exercise = new SuperAdminExerciseServer();
  static ExerciseUser = new SuperAdminExerciseUserServer();
  static DataRecording = new SuperAdminCoachDataRecordingUserServer();
  static CoachSubscription = new CoachSubscriptionServer();
}

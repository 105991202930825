import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Row, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { countries } from 'countries-list';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import FloatInput from '../../components/float-components/float-input';

import FloatSelect from '../../components/float-components/float-select';

const translationPrefix = 'addCoachPage';

export const AddCoachPage: FC = function AddCoachPage() {
  const [wantsToEnterBillingAddress, setWantsToEnterBillingAddress] = useState(false);
  const [form] = useForm<any>();
  const [plans, setPlans] = useState<any[] | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      values.doesBillingEqualShipping = !values.billingAddress;
      if(values.phoneNumber === ""){
        delete values.phoneNumber;
      }
      const response = await ServerController.SuperAdminCoach.post(values);
      if (!IsErrorResDto(response)) {
        navigate('/coaches');
      }
    } finally {
      setLoading(false);
    }
  };
  const load = async () => {
    const plansResponse = await ServerController.PublicPlan.get();
    if (!IsErrorResDto(plansResponse)) {
      setPlans(plansResponse.data);
    }
  };
  useEffect(() => {
    load();
  }, []);

  const plansMemo = useMemo(() => {
    if (!plans) {
      return;
    }
    const results = plans.map((x) => ({ value: x.id, label: x.displayName }));
    return results;
  }, [plans]);

  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      countriesArray.push({
        value: countryKey,
        label: countries[countryKey].name,
      });
    }
    return countriesArray;
  }, []);
  const [isBusinessPurchase, setBusinessPurchase] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="container">
      <Card
        title={
          <>
            <Button className="me-2" type="text" onClick={(x) => navigate('/coaches')}>
              <ArrowLeftOutlined />
            </Button>
            Add new Admin
          </>
        }
      >
        <Form onFinish={onFinish} form={form}>
          <Row className="row-form-item" gutter={30}>
            <Col xs={24} md={12}>
              <FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.email.required`) as string,
                    type: 'email',
                  },
                ]}
              >
                <FloatInput type="email" label={t(`${translationPrefix}.register.email.label`)} required />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name="phoneNumber"
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
              >
                <FloatInput type="string" label={t(`${translationPrefix}.register.phoneNumber.label`)} />
              </FormItem>
            </Col>
          </Row>

          <Row className="row-form-item" gutter={30}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.form.firstName.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.form.firstName.label`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: t(`${translationPrefix}.form.firstName.required`) as string }]}
              >
                <FloatInput label={t(`${translationPrefix}.form.lastName.label`)} required />
              </Form.Item>
            </Col>
          </Row>

          <FormItem label={t(`${translationPrefix}.form.shippingAddress.checkbox.label`)}></FormItem>

          <FormItem
            name={['shippingAddress', 'country']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.form.address.country.required`) as string,
              },
            ]}
          >
            <FloatSelect
              options={countriesMemo}
              showSearch
              label={t(`${translationPrefix}.form.address.country.label`)}
              filterOption={(input, option) => {
                const regex = new RegExp(input, 'i');
                return regex.test(option!.label as string);
              }}
              required
            />
          </FormItem>
          <FormItem
            name={['shippingAddress', 'address']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.form.address.streetAddress.required`) as string,
              },
            ]}
          >
            <FloatInput label={t(`${translationPrefix}.form.address.streetAddress.label`)} required />
          </FormItem>
          <Row className="row-form-item" gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name={['shippingAddress', 'postalCode']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.form.address.postalCode.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.form.address.postalCode.label`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name={['shippingAddress', 'city']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.form.address.city.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.form.address.city.label`)} required />
              </FormItem>
            </Col>
          </Row>

          <FormItem name={['shippingAddress', 'state']}>
            <FloatInput label={t(`${translationPrefix}.form.address.state.label`)} />
          </FormItem>

          <FormItem
            label={t(`${translationPrefix}.form.billingAddress.checkbox.label`)}
            name="wantsToEnterBillingAddress"
          >
            <Checkbox
              checked={wantsToEnterBillingAddress}
              onChange={(event) => setWantsToEnterBillingAddress(event.target.checked)}
            />
          </FormItem>
          {!wantsToEnterBillingAddress ? (
            <></>
          ) : (
            <>
              <FormItem
                name={['billingAddress', 'country']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.form.address.country.required`) as string,
                  },
                ]}
              >
                <FloatSelect
                  options={countriesMemo}
                  showSearch
                  label={t(`${translationPrefix}.form.address.country.label`)}
                  filterOption={(input, option) => {
                    const regex = new RegExp(input, 'i');
                    return regex.test(option!.label as string);
                  }}
                  required
                />
              </FormItem>
              <FormItem
                name={['billingAddress', 'address']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.form.address.streetAddress.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.form.address.streetAddress.label`)} required />
              </FormItem>
              <Row className="row-form-item" gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={['billingAddress', 'postalCode']}
                    rules={[
                      {
                        required: true,
                        message: t(`${translationPrefix}.form.address.postalCode.required`) as string,
                      },
                    ]}
                  >
                    <FloatInput label={t(`${translationPrefix}.form.address.postalCode.label`)} required />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    name={['billingAddress', 'city']}
                    rules={[
                      {
                        required: true,
                        message: t(`${translationPrefix}.form.address.city.required`) as string,
                      },
                    ]}
                  >
                    <FloatInput label={t(`${translationPrefix}.form.address.city.label`)} required />
                  </FormItem>
                </Col>
              </Row>

              <FormItem name={['billingAddress', 'state']}>
                <FloatInput label={t(`${translationPrefix}.form.address.state.label`)} />
              </FormItem>
            </>
          )}

          <FormItem label={t(`${translationPrefix}.form.isBusinessPurchase.checkbox.label`)} name="isBusinessPurchase">
            <Checkbox
              checked={isBusinessPurchase}
              onChange={(event) => {
                setBusinessPurchase(event.target.checked);
                form.setFieldValue('isBusinessPurchase', event.target.checked);
              }}
            />
          </FormItem>
          {!isBusinessPurchase ? (
            <></>
          ) : (
            <>
              {/* <FormItem
                name={['taxInformation', 'businessName']}
                rules={[
                  {
                    required: isBusinessPurchase,
                    message: t(`${translationPrefix}.form.taxInformation.businessName.required`) as string,
                  },
                ]}
              >
                <FloatInput
                  label={t(`${translationPrefix}.form.taxInformation.businessName.label`)}
                  required={isBusinessPurchase}
                />
              </FormItem>
              <FormItem
                name={['taxInformation', 'taxId']}
                rules={[
                  {
                    required: isBusinessPurchase,
                    message: t(`${translationPrefix}.form.taxInformation.taxId.required`) as string,
                  },
                ]}
              >
                <FloatInput
                  label={t(`${translationPrefix}.form.taxInformation.taxId.label`)}
                  required={isBusinessPurchase}
                />
              </FormItem> */}
            </>
          )}
          <FormItem
            name={['planId']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.form.plan.required`) as string,
              },
            ]}
          >
            {plans && (
              <FloatSelect
                options={plansMemo}
                showSearch
                label={t(`${translationPrefix}.form.plan.label`)}
                filterOption={(input, option) => {
                  const regex = new RegExp(input, 'i');
                  return regex.test(option!.label as string);
                }}
                required
              />
            )}
          </FormItem>
          <Button
            type="primary"
            onClick={async () => {
              await form.validateFields();
              form.submit();
            }}
          >
            {isLoading ? <Spin spinning={isLoading}></Spin> : t(`Create Coach`)}
          </Button>
        </Form>
      </Card>
    </div>
  );
};
